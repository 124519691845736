type enObject = {
  [key: string]: string;
};

const en: enObject = {
  "page.title": "Amazon Day 1",
  "button.next": "Next",
  "button.back": "Back",
  "button.done": "Done",
  "text.then": "Then",
  "text.chatWithITSupport": "Chat with IT Support",
  "videoGuidance.title": "Video guidance",
  "videoGuidance.example": "Example",
  "videoGuidance.button.altText": "Show video guidance",
  "icon.title": "Completed",
  "error.notSupportVideoTag": "Your browser does not support the video tag.",
  "welcome.expander.title": "Welcome to Amazon 🎉",
  "welcome.expander.body.greeting":
    "We're glad you're here. We will guide you through setting up your new laptop.",
  "welcome.expander.body.requirements.start": "What you will need:",
  "welcome.expander.body.requirements.list1":
    "Your security key we mailed to you.",
  "welcome.expander.body.requirements.list2": "A Wi-Fi connection.",
  "welcome.expander.body.requirements.list3":
    "Your Embark Pre-Boarding portal username and password.",
  "welcome.expander.body.requirements.list4":
    "Access to your personal email to receive additional information throughout the process.",
  "welcome.expander.body.firstStep.start": "First step: Connect to Wi-Fi",
  "welcome.expander.body.firstStep.list1":
    "Click on the Wi-Fi icon on the taskbar.",
  "welcome.expander.body.firstStep.list2": "Select your Wi-Fi connection.",
  "welcome.expander.body.firstStep.list3":
    'Enter your Wi-Fi password, and click "Connect".',
  "welcome.button.getStarted": "Get Started",
  "identityCheck.expander.title": "Identity verification",
  "identityCheck.expander.body.header": "Record videos",
  "identityCheck.expander.body.intro":
    "Record videos holding your ID documents next to your face, and complete a series of hand gestures. Send these videos to an Amazon Human Verifier to review.",
  "identityCheck.expander.body.light.instruction":
    "Once you begin the ID gesture challenge, you'll have <b>10 minutes</b> to record and submit a single <b>10 second video</b> of yourself completing the gestures.",
  "identityCheck.expander.body.helpLinkPre":
    "Unable to complete the actions? Please {chatWithITSupportLink} to verify your identity on a Chime video call.",
  "identityCheck.expander.body.helpLinkPost":
    "to verify your identity on a Chime video call.",
  "identityCheck.challenges.title": "Challenge Actions",
  "identityCheck.challenges.completed":
    "Your challenge actions have been recorded.",
  "identityCheck.challenges.instructions.completeByTime":
    "Please complete this challenge by {time}",
  "identityCheck.challenges.instructions":
    "Follow the instructions to record a single 10 second video performing these two gestures:",
  "identityCheck.challenges.expired":
    "<b>Your time limit has expired.</b> Please {chatWithIt}. You will be asked to join a video call and show your ID to an IT Support Engineer.",
  "identityCheck.challenges.instructions.unhideButton":
    "Start gesture challenge",
  "identityCheck.challenges.forSeconds": "for {second} seconds.",
  "identityCheck.button.recording": "Recording: {countDownTimer}",
  "identityCheck.button.startRecord": "Start Recording",
  "identityCheck.button.enableCamera": "Enable Camera",
  "identityCheck.button.retry": "Retry",
  "identityCheck.do.title": "Do's",
  "identityCheck.do.hint1": "Look straight into the camera.",
  "identityCheck.do.hint2": "Use proper light exposure.",
  "identityCheck.dont.title": "Dont's",
  "identityCheck.dont.hint1": "Cover your ID with your fingers or hand.",
  "identityCheck.dont.hint2": "Cover your face with a mask.",
  "identityCheck.dont.hint3":
    "Wear sunglasses (prescription glasses are okay).",
  "identityCheck.dont.hint4":
    "Wear hats or caps (religious headwear that does not cover your face is okay).",
  "identityCheck.full.steps.recordPrimaryId": "Record video holding primary ID",
  "identityCheck.full.steps.recordSecondaryId":
    "Record video holding secondary ID",
  "identityCheck.full.steps.recordChallengeActions": "Record challenge actions",
  "identityCheck.full.primaryId.expandAltText": "Click to upload primary Id",
  "identityCheck.full.secondaryId.expandAltText":
    "Click to upload secondary Id",
  "identityCheck.full.challengeActions.expandAltText":
    "Click to upload challenge actions",
  "identityCheck.primaryId.instruction":
    "Record a 15 second video holding your physical primary ID beside your face and then up to the camera. This ID " +
    "must be an unexpired government-issued ID from this list of accepted documents. It must show your name, date " +
    "of birth, and photograph.",
  "identityCheck.secondaryId.instruction":
    "Record a 15 second video holding your physical secondary ID beside your face and then up to the camera. " +
    "secondary ID can be a different government-issued ID (for example, a driver's license) or a " +
    "non-government-issued photo ID (for example, a Costco card or a student ID).",
  "identityCheck.primaryId.acceptableDocsTitle":
    "Acceptable Primary documents include:",
  "identityCheck.primaryId.driverLicense": "U.S. driver's license",
  "identityCheck.primaryId.passport": "U.S. passport",
  "identityCheck.primaryId.passportCard": "U.S. passport card",
  "identityCheck.primaryId.prCard": "Permanent Resident Card (Form 1-551)",
  "identityCheck.primaryId.ead":
    "Employment Authorization Document (Form 1-766)",
  "identityCheck.primaryId.alienReceiptCard": "Alien Registration Receipt Card",
  "identityCheck.primaryId.foreignPassport": "Foreign passport",
  "identityCheck.primaryId.select.label": "ID Type",
  "identityCheck.primaryId.select.placeholder": "Select ID Type",
  "identityCheck.primaryId.video.instruction":
    "Hold your Primary ID next to your face, then move it toward the camera.",
  "identityCheck.primaryId.video.instruction2":
    "Hold your ID 3 inches from the camera until it is in focus.",
  "identityCheck.primaryId.completed":
    "Your Primary ID and Face have been recorded.",
  "identityCheck.secondaryId.completed":
    "Your Secondary ID and Face have been recorded.",
  "identityCheck.primary.title": "Record video holding your primary ID",
  "identityCheck.secondaryId.title": "Record video holding your secondary ID",
  "identityCheck.secondaryId.video.instruction":
    "Hold your Secondary ID next to your face, then move it toward the camera.",
  "identityCheck.secondaryId.video.instruction2":
    "Hold your ID 3 inches from the camera until it is in focus.",
  "identityCheck.button.goBackAltText": "Go back",
  "identityCheck.button.submitReview": "Submit for review",
  "identityCheck.button.loading": "Submitting...",
  "identityCheck.button.tooltip.disallowResubmit":
    "The video recording has already been submitted.",
  "identityCheck.button.tooltip.missingPrimaryIdType":
    "Please select a primary ID type from the select dropdown.",
  "identityCheck.errors.unableToVerify":
    "We were unable to verify your identity. Please {link}. You'll be asked to join a video call and show your ID " +
    "to an Amazon IT Support agent, and you'll be provided with another path to set up your laptop.",
  "identityCheck.errors.unableToVerifyFallback":
    "<b>We couldn't verify your identity using Manual Identity Verification.</b> Please try to use <a>Biometric Identity Verification</a> instead.",
  "identityCheck.errors.failedToSubmit":
    "We were unable to submit your video. Please try again in a few seconds or {link} for further assistance.",
  "identityCheck.errors.chatWithIT": "Chat with IT Support",
  "identityCheck.pending.pleaseWait":
    "While your videos are being verified, please don't navigate away from this page.",
  "identityCheck.pending.estimatedWaitTime":
    "Typical waiting time for identity verification is {minTimeMin} to {maxTimeMin} minutes.",
  "identityCheck.verified.successMsg": "We have verified your identity.",

  "identityCheck.ivv.body.intro":
    "Setting up your Amazon username and password requires an additional layer of security to prove you are you, and not someone pretending to be you.",
  "identityCheck.ivv.body.consentcontent.header":
    "Consent for use of biometrics",
  "identityCheck.ivv.body.consentcontent.legalbody": `
  <p>As a part of the process for issuing Amazon system access credentials, Amazon must verify your identity. To do so, Amazon utilizes an automated biometric identity verification system ("System"). Use of the System is completely optional and voluntary. The System works as follows: upload a photo of a government-issued photo identification ("Primary Photo ID"), a photo of another government-issued photo ID ("Secondary Photo ID"), and a video selfie of yourself. The name on both the Primary Photo ID and Secondary Photo ID must match or be substantially similar to the name Amazon has on file. The System verifies your identity by taking biometric measurements of your face ("facial data") from the video selfie, and comparing it to the facial data from both the Primary Photo ID and Secondary Photo ID.</p> 
  
  <u><b>What Information is Collected and By Whom</b></u>
  
  <p>Amazon will provide the following personal information to its third-party service provider, Persona:</p>
  <ul>
    <li>Your legal full name</li>
  </ul>
  
  <p>Amazon does not collect your facial data.</p>
  <p>Persona will collect the following personal information:</p>
  <ul>
  <li>Photo(s) of one of your government ID,</li>
  <li>Photo(s) of one other government ID,</li>
  <li>A video selfie of you</li>
  <li>
    Facial biometric information (generated from both the submitted IDs and the video selfie).
  
  <p>Your personal information and facial data will be used solely for identification and verification purposes. Persona will destroy all personal information and facial data within 7 days of completing the verification process, unless otherwise required by law. Persona will not share your personal information or facial data. Persona will store the data in a confidential and secure manner prior to destruction.</p>
  </li>
  </ul>
`,
  "identityCheck.ivv.body.consentcontent.moreInfo":
    "For more information, please consult the {gEmployeePrivacyNoticeLink} / {caEmployeePrivacyNoticeLink}.",
  "identityCheck.ivv.body.consentcontent.gEmployeePrivacyNoticeLink":
    "Amazon Global Employee Privacy Notice",
  "identityCheck.ivv.body.consentcontent.caEmployeePrivacyNoticeLink":
    "Amazon California Employee Privacy Policy and Notice",
  "identityCheck.ivv.body.consentcontent.checkboxbody": `I consent to Amazon's collection and use of my biometric data in connection with the automated identity verification. I also consent for the automated identity verification to receive my Amazon username and password.`,
  "identityCheck.ivv.body.consentcontent.checkboxsubbody": `If you consent to proceed with the System, select "Opt In" to proceed. By selecting "Opt In" you will have successfully opted in to use of the System. By checking this box and clicking "Agree" you acknowledge that; you have read and understand the above, you understand that the above information is available at any time upon request, you understand that the use of this process is optional and voluntary, you are physically located in the United States at this point in time, and you consent to, and authorize, the collection and processing of your facial data (including any related biometric information) and personal information as described above.`,
  "identityCheck.ivv.body.consentcontent.cancelbtn": "Cancel",
  "identityCheck.ivv.body.consentcontent.agreebtn": "Agree",
  "identityCheck.ivv.body.consentcontent.agreebtnrequired":
    "Please check this box and click Agree to acknowledge that you have read and understand the above, or click Cancel to verify your identity using a manual process.",
  "identityCheck.ivv.failedBody":
    "<b>We couldn't verify your identity using an Automated Face Scan. </b>Please <a>Record Videos</a> holding your ID documents next to your face, and complete a series of hand gestures.",
  "identityCheck.ivv.failedBodyNoFallback":
    "<b>We couldn't verify your identity using an Automated Face Scan.</b> {chatWithITSupportLink} for further assistance.",
  "identityCheck.ivv.inFlight":
    "You have an in-flight verification already and cannot start a new verification. Please refresh the page periodically to check the status.",
  "identityCheck.ivv.cantLoadWidget":
    "<b>Our system is down.</b> Please refresh. If you are unable to continue. Please {chatWithITSupportLink}.",
  "identityCheck.ivv.pendingBackendAgreementPre":
    "<b>Finalizing your identity check</b>",
  "identityCheck.ivv.pendingBackendAgreement": `This may take a few minutes. If you've been waiting more than 10 minutes, use the "Get support" button for assistance.`,
  "identityCheck.pathchooser.title":
    "Select your verification preference from one of the following methods.",
  "identityCheck.pathchooser.card.recommended": "Recommended",
  "identityCheck.pathchooser.card.estCompletion":
    "Time to complete: <b>{count} {unit}</b>",
  "identityCheck.pathchooser.card.unitMinute": "minute",
  "identityCheck.pathchooser.card.unitMinutes": "minutes",
  "identityCheck.pathchooser.card.verificationTypeAutomated":
    "Verification type: <b>Automated</b>",
  "identityCheck.pathchooser.card.verificationTypeManual":
    "Verification type: <b>Manual</b>",
  "identityCheck.pathchooser.card.errorSelectionRequired":
    "A verification type must be selected",
  "identityCheck.pathchooser.card.errorAnyInFlight":
    "A verification is in-flight already",
  "identityCheck.pathchooser.card.ivv.title": "Automated Face Scan",
  "identityCheck.pathchooser.card.ivv.body":
    "Scan your face on your first day and we'll compare it to the photo on your government-issued ID. Your identity will be confirmed immediately and you'll be ready to go.",
  "identityCheck.pathchooser.card.manual.title": "Record Videos",
  "identityCheck.pathchooser.card.manual.body":
    "Record videos holding your ID documents next to your face, and complete a series of hand gestures. Send these videos to an Amazon human verifier to review.",
  "identityCheck.pathchooser.card.contactsupport.title": "Contact support",
  "identityCheck.pathchooser.card.contactsupport.body":
    "Chat with an IT support engineer in real time, and verify your identity through a video conference call on Amazon Chime, who'll guide you through a manual setup process.",
  "identityCheck.pathchooser.submitBtn": "Submit",
  "amazonUsername.expander.title": "Amazon username",
  "amazonUsername.expander.body.info":
    "At Amazon, you will use your username to log into your computer and access Amazon tools and resources. It is the " +
    "part of your Amazon email address that comes before the @. Your email address is {emailAddress}.",
  "amazonUsername.expander.body.username":
    "Your Amazon username is: {username}",
  "registerSecurityKey.expander.title": "Register security key",
  "registerSecurityKey.expander.body.intro":
    "At Amazon, we use your security key as a step in multi-factor authentication to make sure it's you. You will be " +
    "prompted to press your security key to access the Amazon Network, daily. Each time you press your key, it will " +
    "generate a random string of characters.",
  "registerSecurityKey.expander.body.securityKeyTitle":
    "What is a security key?",
  "registerSecurityKey.expander.body.securityKeyDescription1":
    "A security key is a small, plastic device used to securely access Amazon resources. When your Amazon laptop was " +
    "shipped to you, we also mailed you two USB security keys. The second key is your backup key - store it in a " +
    "secure place in case your primary key is lost or damaged.",
  "registerSecurityKey.expander.body.securityKeyDescription2":
    "At Amazon, we use your security key as a step in multi-factor authentication to make sure you're you. To use many " +
    "of Amazon's internal tools and resources each day, you'll enter your password along with your security key to " +
    "access the Amazon network. Choose a security key PIN that's easy to remember. You'll type it every day when you " +
    "log in to internal Amazon services and VPN. It doesn't expire.",
  "registerSecurityKey.expander.body.midwayTitle": "What is Midway?",
  "registerSecurityKey.expander.body.midwayDescription":
    "Midway is the service that manages security keys and security key PINs.",
  "registerSecurityKey.expander.body.howTitle":
    "How do I register my security key?",
  "registerSecurityKey.expander.body.step1":
    "Send a temporary PIN to your personal email to access Midway. Open your personal email to access this Temporary" +
    " PIN.",
  "registerSecurityKey.expander.body.pinSentConfirmation":
    "We have sent a verification code to your personal email. Your PIN will expire in 4 hours. Didn't receive a code? " +
    "{resendLink}",
  "registerSecurityKey.expander.body.step2":
    "Once you have the Temporary PIN, register your key on Midway. You'll be asked to enter your Amazon username and your " +
    "Temporary PIN.",
  "registerSecurityKey.expander.body.step3":
    "Plug either of your security keys into a USB port on this laptop. You might " +
    "need to use 1 of the adapters that you received. (You only need to register 1 of your " +
    "security keys. The second key is a backup.) Carefully insert this key with the pinhole " +
    "facing out. You'll see a green light when this security key is inserted correctly.",
  "registerSecurityKey.expander.body.step4":
    "At the Sign in page, enter the following:",
  "registerSecurityKey.expander.body.step4.1":
    "<bold>Amazon username:</bold> the username displayed in the previous step.",
  "registerSecurityKey.expander.body.step4.2":
    "<bold>PIN (or security key PIN):</bold> the temporary PIN sent to your personal email address.",
  "registerSecurityKey.expander.body.step5":
    "After you've entered your username and temporary security key PIN, click <bold>Sign in</bold>:",
  "registerSecurityKey.expander.body.step6": `You'll see a message that says "Welcome {username}!"`,
  "registerSecurityKey.expander.body.step7":
    "Click the <bold>security key registration portal</bold> link in the welcome message to continue.",
  "registerSecurityKey.expander.body.step8": `On the page that reads "Welcome to security key dashboard," click <bold>Register security key</bold>.`,
  "registerSecurityKey.expander.body.step9": `On the page that reads "Get started with your security key," click <bold>Begin registration</bold>. If you see two options here, choose Begin hardware security key registration.`,
  "registerSecurityKey.expander.body.step10":
    "Select the image that matches the security key that you received.",
  "registerSecurityKey.expander.body.step11": `Next, you'll enter the one-time password (OTP) generated by your security key. Click in the empty text box that says "Your OTP will appear here, do not type in this box".`,
  "registerSecurityKey.expander.body.step12.1":
    "Press the security key that's plugged into your computer. Apply light pressure for 3-5 seconds until a string of text appears in the empty text box. This is the OTP. Depending on the type of security key you have, you'll need to apply light pressure to either its <bold>side</bold> or <bold>top</bold>.",
  "registerSecurityKey.expander.body.step12.2":
    "<bold>If you're pressing your security key but it isn't entering an OTP</bold>, remove your finger from the security key, click the text box, and then touch the security key again.",
  "registerSecurityKey.expander.body.step13":
    "Type the permanent security key PIN you'd like to use in the <bold>New PIN</bold> and <bold>Confirm new PIN</bold> fields, then click <bold>Continue</bold>.",
  "registerSecurityKey.expander.body.step13.1":
    "Type the permanent security key PIN you'd like to use in the <bold>New PIN</bold> and <bold>Confirm new PIN</bold> fields, then click <bold>Continue</bold>.",
  "registerSecurityKey.expander.body.step13.2":
    "<bold>Important</bold>: Choose a security key PIN that's easy to remember. You'll type it every day when you log in to internal Amazon services and VPN. It doesn't expire.",
  "registerSecurityKey.expander.body.step14":
    'On the page that reads "Please read all instructions below..." press <bold>Continue</bold>.',
  "registerSecurityKey.expander.body.step15":
    "On the next page, press and hold your security key again. You might get a pop-up requesting information about your security key. Click <bold>Allow</bold>.",
  "registerSecurityKey.expander.body.screenPossibilityListTitle":
    "Next, you'll see 1 of 2 screens:",
  "registerSecurityKey.expander.body.screenPossibilityList.1": `If you see "<bold>You've successfully registered your security key</bold>", you're done with this step. Keep your second security key safe. Make sure you register it later as a backup if you lose the one you've just registered.`,
  "registerSecurityKey.expander.body.screenPossibilityList.2":
    "If you're prompted to enter a PIN, you aren't done yet. Proceed with the following steps:",
  "registerSecurityKey.expander.body.setUpNewPin.1":
    "Type your <bold>security key PIN</bold> into the <bold>PIN</bold> and <bold>Confirm PIN fields</bold>, then click <bold>Next</bold>.",
  "registerSecurityKey.expander.body.setUpNewPin.2":
    "On the next prompt, press and hold your security key again. You might get a pop-up requesting information about your security key. Click <bold>Allow</bold>.",
  "registerSecurityKey.expander.body.setUpNewPin.3": `If registration is successful, you'll see "<bold>You've successfully registered your security key</bold>." If you don't see this message, try the registration process again in a different browser.`,
  "registerSecurityKey.expander.body.setUpNewPin.3.1": `If registration is successful, you'll see <bold>"You've successfully registered your security key."</bold>`,
  "registerSecurityKey.expander.body.setUpNewPin.3.2": `If you don't see this message, you will need to retry the registration step. Close the Midway window on the right, and retry this step by clicking on the "Register security key on Midway" button to retry this step.`,
  "registerSecurityKey.expander.body.secondKeySafe":
    "Keep your second security key safe. You can register it later as a backup if you lose the one you've just registered.",
  "registerSecurityKey.expander.body.note": `<bold>Note</bold>: If you encounter an error related to "Device Compliance" or "AEA", you will need to restart Easy Onboarding by logging out of "tokenadmin" and logging back in to allow for device updates to complete. Please follow the instructions provided to you in your personal email address to restart this process. If this does not solve the error, please use the "Contact Support" link for assistance.`,
  "registerSecurityKey.checkbox.confirmation":
    "I confirm that I have registered my security key.",
  "registerSecurityKey.button.sendTempPin": "Send Temporary PIN",
  "registerSecurityKey.button.sendTempPin.loadingText": "Sending...",
  "registerSecurityKey.button.sendTempPin.error":
    "An error occurred in sending the PIN. Please try again in a few minutes.",
  "registerSecurityKey.button.sendTempPin.errorContactSupport":
    "We weren't able to send you a temporary PIN. Please contact support.",
  "registerSecurityKey.button.registerSecurityKey":
    "Register security key on Midway",
  "registerSecurityKey.link.resend": "Resend.",
  "registerSecurityKey.image.securityKeyGrid.altText":
    'A grid of six images showing security key types. The types are "YubiKey 4", "YubiKey 4 Nano", "USB-C YubiKey", "USB-C Nano", "Zukey", and "USB-C Zukey".',
  "registerSecurityKey.image.tempPinLogin.altText":
    'A screenshot of the Amazon Midway Sign in page. The page says "Sign in", includes fields to enter an Amazon username and Amazon PIN, and has a button that says "Sign in".',
  "registerSecurityKey.image.midwayWelcomeMessage.altText":
    'A screenshot of an example page with a welcome message. The example welcome page says "Welcome jeffbezos!. To register or manage your security keys, visit the security key registration portal."',
  "registerSecurityKey.image.securityKeyDashboard.altText":
    'A screenshot of the Amazon Enterprise Access security key dashboard welcome page. The page says "Welcome to security key dashboard. Amazon Enterprise Access" and has a button that says "Register Security Key".',
  "registerSecurityKey.image.securityKeyGetStarted.altText":
    'A screenshot of the Get started with your security key page. The page says "Get started with your security key. Pick up a security key at your nearest IT Support office or IT vending machine. After your pick up a security key, click Begin registration. Important: You must have your new security key with you to register it. You must plug in your new security key at this point." and has a button that says "Begin registration".',
  "registerSecurityKey.image.securityKeyGetStartedGrid.altText":
    'A screenshot of the Get started with your security key page. The page says "Get started with your security key. Which kind of security key did you pick up? Select the security key that matches the one you have." and has the grid of six security key types. The security key types listed are "YubiKey 4", "YubiKey 4 Nano", "USB-C YubiKey", "USB-C Nano", "Zukey", and "USB-C Zukey".',
  "registerSecurityKey.image.securityKeyGetStartedOtp.altText":
    'A screenshot of the Get started with your security key page. The page says "Get started with your security key. First, insert your security key in a USB port on your computer. Second, press and hold the security key for 3-5 seconds until text is displayed in the box below." and has a text input field with filler text that says "Your OTP will appear in here, do not type in this box".',
  "registerSecurityKey.image.pressingSecurityKeySide.altText":
    "An animated image gif of a person pressing the side of a security key that is plugged into a laptop",
  "registerSecurityKey.image.pressingSecurityKeyTop.altText":
    "An animated image gif of a person pressing the top of a security key that is plugged into a lapop",
  "registerSecurityKey.image.securityKeyCreatePin.altText":
    'A screenshot of the security key PIN creation page. The page says "You are required to set a new security key PIN in order to register this security key. Do NOT forget your security key PIN. Your PIN will be used to allow you to log in to Amazon resources and connect to the VPN with your security key". The page also includes a text input field labeled "New PIN (8-64 ASCII chars)", another text input field labeled "Confirm new PIN", and a button that says "Continue".',
  "registerSecurityKey.image.securityKeySuccess.altText":
    "A screenshot of a success message. The message says \"You've successfully registered your security key. It may take up to 1 hour for this security key to work on all of Amazon's tools and resources.\"",
  "registerSecurityKey.image.securityKeyCreateNewPin.altText":
    'A screenshot of a pop-up form to set up a new PIN. The pop-up says "PIN required. Set up a new PIN for your security key". The pop-up also has a text input field labeled "PIN" and another text input field labeled "Confirm PIN".',
  "connectVpn.expander.title": "Connect to the Amazon Network (VPN)",
  "connectVpn.expander.body.intro":
    "You will need to connect to VPN to access internal resources while you're working on-site or remotely. VPN " +
    "sessions time out every 18 hours and then require you to reconnect.",
  "connectVpn.expander.body.mac.step1":
    "Open <bold>Cisco AnyConnectSecure Mobility Client</bold>.",
  "connectVpn.expander.body.mac.step2":
    "The Cisco AnyConnect client will appear. <bold>Click Connect</bold>.",
  "connectVpn.expander.body.mac.step3":
    "A pop up will appear. In the <bold>Group</bold> field, select <bold>orca-Amazon-Onboarding</bold> from the drop-down list.",
  "connectVpn.expander.body.mac.step4":
    "Enter your Amazon username in the <bold>Username</bold> field.",
  "connectVpn.expander.body.mac.step5":
    "In the Security key PIN + Press security key field, type your <bold>security key PIN</bold> and then press and hold your security key until a long string of characters appears in the field. Don't click OK - when you press and hold your security key, the VPN connection will automatically start to authenticate.",
  "connectVpn.expander.body.mac.step5.warning":
    "The <bold>Security key PIN + Press security key field</bold> might say <bold>Password</bold> instead. Don't enter your Amazon password here. Enter your security key PIN and then press and hold your security key.",
  "connectVpn.expander.body.mac.step6":
    "A pop up will appear, confirming you're connected to the Amazon network over VPN. Select, <bold>OK</bold>.",
  "connectVpn.image.mac.step2.altText":
    "Screenshot of the Cisco AnyConnect Secure Mobility Client application.",
  "connectVpn.image.mac.step3.altText":
    "Screenshot of a pop-up with Group, Username, and Security key PIN fields to connect to the VPN with the Cisco AnyConnect " +
    "Secure Mobility Client application with orca-Amazon-Onboarding selected from the drop-down list in the Group field.",
  "connectVpn.image.mac.step4.altText":
    "Screenshot of a pop-up with Group, Username, and Security key PIN fields to connect to the VPN with the Cisco AnyConnect " +
    "Secure Mobility Client application with a red box highlighting the Username field.",
  "connectVpn.image.mac.step5.altText":
    "Screenshot of a pop-up with Group, Username, and Security key PIN fields to connect to the VPN with the Cisco AnyConnect " +
    "Secure Mobility Client application with a red box highlighting the Security key PIN + Press security key field and the OK button crossed out with a red X.",
  "connectVpn.image.mac.step6.altText":
    "Screenshot of a successfully connection to the VPN.",
  "connectVpn.expander.body.windows.step1":
    'Press the <bold>Windows key</bold> on this laptop which will allow access to the "Start Menu".',
  "connectVpn.expander.body.windows.step2":
    "From the Start menu, select <bold>VPN Launch</bold>.",
  "connectVpn.expander.body.windows.step3":
    "The Cisco AnyConnect client will appear. The Cisco AnyConnect software is what is used for Amazon's VPN. Click <bold>Connect</bold>.",
  "connectVpn.expander.body.windows.step4":
    "Click to open the drop-down menu in the Group field. Select <bold>orca-Amazon-Onboarding</bold>.",
  "connectVpn.expander.body.windows.step5":
    "Enter your Amazon username in the <bold>Username</bold> field.",
  "connectVpn.expander.body.windows.step6":
    "In the <bold>Password</bold> field, type your security key PIN and then press and hold your security key until a long string of characters appears in the field. Don't click <bold>OK</bold>-when you press and hold your security key, the VPN connection will automatically start to authenticate.",
  "connectVpn.expander.body.windows.step7":
    "When the VPN is connected, a security notification will appear confirming the connection. Click <bold>OK</bold> to dismiss the dialog box.",
  "connectVpn.image.windows.step1.altText": "Press the Windows key.",
  "connectVpn.image.windows.step2.altText":
    "Select VPN Launch from the start menu.",
  "connectVpn.image.windows.step4.altText":
    "Select orca-Amazon-Onboarding from the drop-down list in the Group field.",
  "connectVpn.image.windows.step6.altText":
    "Type your security key PIN and then press and hold your security.",
  "connectVpn.button.tooltip.vpnNotConnectedHint": "Connect to VPN.",
  "amazonPassword.expander.title": "Create Amazon Password",
  "amazonPassword.expander.body.intro1":
    "Next, you'll create the password that you'll use to sign in to your laptop each day and access Amazon tools.",
  "amazonPassword.expander.body.intro2":
    "You'll type your security key PIN and then press and hold your finger against your USB security key, to set your " +
    "password for the first time. Please <bold>remember this password</bold>, as you'll need it to continue " +
    "setting up your laptop.",
  "amazonPassword.expander.body.banner":
    "When creating your password, you'll be asked to press your finger against your USB security key. This will press " +
    '"enter" for you. There\'s no need to click "enter" or "submit". If you receive an error, please ignore it and ' +
    "continue with your laptop setup.",
  "amazonPassword.expander.body.confirmation":
    "I confirm that I have successfully created my password.",
  "amazonPassword.button.createPassword": "Create Amazon password",
  "preCacheADMobile.expander.title": "Install Pre-Cache AD Mobile",
  "preCacheADMobile.expander.body.intro":
    "At Amazon, we use a tool called Precache AD to synchronize your account information located on Amazon's network " +
    "to the laptop you're using. This ensures that you can log into your Amazon laptop using your Amazon password " +
    "and username each day.",
  "preCacheADMobile.expander.body.step1":
    "Open the <bold>Self Service</bold> {logo} Application on your desktop.",
  "preCacheADMobile.expander.body.step2": "Enter your Amazon username.",
  "preCacheADMobile.expander.body.step3":
    "Enter your security key PIN and press your security key.",
  "preCacheADMobile.expander.body.step4":
    'In the search box in the top left, enter "<bold>Pre-Cache</bold>".',
  "preCacheADMobile.expander.body.step5":
    "When the search results load, click <bold>Cache</bold>.",
  "preCacheADMobile.expander.body.step6":
    "Enter your Amazon username. Click <bold>OK</bold>.",
  "preCacheADMobile.expander.body.step7":
    "Enter your Amazon password. Click <bold>OK</bold>.",
  "preCacheADMobile.expander.body.step8":
    'The script will run silently for 2-3 minutes. When complete, a "Success!" pop-up appears. Click <bold>OK</bold>.',
  "preCacheADMobile.image.step3.altText":
    "Screenshot of the view for signing in with your Amazon username and security key PIN.",
  "preCacheADMobile.image.step4.altText":
    "Screenshot of the view for searching for Pre-Cache.",
  "preCacheADMobile.image.step6.altText":
    "Screenshot of the view for entering your Amazon username.",
  "preCacheADMobile.image.step7.altText":
    "Screenshot of the view for entering your Amazon password.",
  "preCacheADMobile.image.step8.altText":
    "Screenshot of view of the Pre-Cache AD Mobile app installed successfully.",
  "preCacheADMobile.logo.altText":
    "Logo of the pre-cache AD app installable via self service",
  "banner.openViaWelcome":
    'Please open this page by clicking "Get started" in the welcome page.',
  "allStepsCompleted.title": "👏 Great job!",
  "allStepsCompleted.nextTo": "Next, you will need to:",
  "allStepsCompleted.postLoginStep1": "Log out of this account.",
  "allStepsCompleted.postLoginStep2":
    "Check your personal email for instructions to log into your Amazon account.",
  failedToRender:
    "Something went wrong. Please visit {link} and contact IT Support for further assistance.",
  "error.serviceNotAvailable.text1":
    "The Amazon Day 1 app is currently not available.",
  "error.serviceNotAvailable.text2":
    "If you're a new hire, go to the IT Setup Guide at firstaid.amazon-corp.com/vnho from either your Amazon device or your personal internet-connected device to complete laptop setup. You will need either your hiring manager or IT Support to assist with the setup process. If your hiring manager is unavailable, please use the “Get Support” link located on the First Aid instructions page.",
  "error.somethingWentWrong":
    "Something went wrong. Please try again or {chatWithITSupportLink} for further assistance.",
  "error.accountIssues":
    "There is an issue with your account. Please {chatWithITSupportLink}.",
  "error.ineligibleError.text1":
    "There's an issue with your account. Please read below before contacting support.",
  "error.ineligibleError.text2":
    "If you're a new hire, go to the IT Setup Guide at firstaid.amazon-corp.com/vnho from your personal internet-connected device to complete laptop setup.",
  "error.ineligibleError.text3":
    "If you're an Amazonian setting up your replacement laptop, follow the instructions that were shipped with the laptop.",
  "error.ineligibleError.text4":
    "If you still need help, go to firstaid.amazon-corp.com for support.",
  "error.beforeStartDate":
    "Your start date and first day is {startDate}. Please close your laptop until that time. Need help? {chatWithITSupportLink}.",
  "itHelpMsg.day1BlockingIssue":
    "I am having issues with starting the Easy Onboarding new hire process and need assistance with my first day laptop setup.",
  "itHelpMsg.contactSupportOnboardThroughChime":
    "I need assistance verifying my identity for new hire onboarding through a Chime video call.",
  "itHelpMsg.verificationIssueAndAssist":
    "I am having issues with verifying my identity through the Easy Onboarding new hire process and need assistance with my first day laptop setup.",
  "itHelpMsg.verificationIssue":
    "I am having issues with setting up my laptop as part of the new hire onboarding process and need help with verifying my identity.",
  "itHelpMsg.verificationAssist":
    "I need assistance verifying my identity as part of the Easy Onboarding new hire process and need help onboarding.",
  "itHelpMsg.settingUpLaptop":
    "I am having issues with setting up my laptop as part of the new hire onboarding process.",
  "itHelpMsg.usernameIssue":
    "I am having issues with my username as part of my new hire onboarding process.",
  "itHelpMsg.midwayAssist":
    "I need assistance with Midway and the security key registration process as part of my new hire onboarding.",
  "itHelpMsg.midwayIssue":
    "I am having issues with the Midway key registration process as part of my new hire onboarding. I have already requested a temporary PIN be sent.",
  "itHelpMsg.vpnIssue":
    "I am having an issue related to the VPN connection as part of my new hire onboarding.",
  "itHelpMsg.passwordIssue":
    "I am having an issue with the Password Reset tool as part of my new hire onboarding.",
  "itHelpMsg.preCacheADIssue":
    "I am having an issue with the Pre-Cache AD step in my new hire onboarding for my Mac device.",
  "mediacheck.failure.permission":
    "To allow access, click the camera icon {icon} in your browser's address bar. You may need to reload the page for changes to apply.",
  "mediacheck.failure.nodevice":
    "No camera devices were found. Please ensure a camera is plugged in and working then reload the page.",
  "mediacheck.failure.other":
    "A problem prevented the page from detecting if you have a usable camera installed.",
  "mediacheck.failure.title": "Camera is blocked.",
  "mediacheck.failure.cameraalt": "Camera icon",
  "cameraOn.text": "Start recording, then {text}",

  // Get Support specific strings
  "getSupport.header": "Get support",
  "getSupport.footer": "Still need support?",

  "getSupport.chatSupport.clickToStart": "Start chat",
  "getSupport.chatSupport.clickToStartNew": "Start new chat",

  // Get Support FAQ contents
  // Maintained currently in: https://quip-amazon.com/eb2BArbaEOFi/FAQ-Content-by-Step-Dec-15th
  "getSupport.faq.password.header": "Amazon Password FAQ",

  "getSupport.faq.password.section1.title": "When does my password expire?",
  "getSupport.faq.password.section1.contents": "",

  "getSupport.faq.vpn.header": "VPN FAQ",
  "getSupport.faq.vpn.section1.title": "Cisco CSD Configuration error",
  "getSupport.faq.vpn.section1.contents": "Placeholder",

  "getSupport.faq.general.header": "General FAQs",
  "getSupport.faq.general.section1.title":
    "What do I do if I haven't received a verification code?",
  "getSupport.faq.general.section1.contents": " verification code",

  "getSupport.faq.midway.header": "Midway FAQs",
  "getSupport.faq.midway.section1.title": "What is a security key?",
  "getSupport.faq.midway.section1.contents":
    "A Security Key is a physical USB stick that will be inserted into your computer. We mailed you a package with two USB Security Keys. Save the second backup key in a safe place, in case your primary key is lost or damaged.",

  "getSupport.faq.username.header": "Amazon Username FAQ",
  "getSupport.faq.username.section1.title": "What is an Amazon Username?",
  "getSupport.faq.username.section1.contents":
    "At Amazon, you'll use your username to log in to your computer and access Amazon tools and resources. Your username is the part of your Amazon email address that comes before the @.",

  "arialabels.clickToCloseChat": "Click to close chat support panel",
  "arialabels.clickToStartChat": "Click to start chat support",
  "arialabels.clickToOpenChat": "Click to open chat support panel",
  "arialabels.clickToCloseGetSupport": "Click to close get support panel",
  "arialabels.clickToOpenGetSupport": "Click to open get support panel",
};

export default en;
